<template>
    <Button label="Novo Usuário" icon="pi pi-plus" @click="toInserir()" v-if="$temAcessoView('CTRLACS-USR-01')" />
</template>

<script>
export default {
    methods: {
        toInserir() {
            this.$router.push({
                name: 'Usuarios_Inserir',
            });
        },
    },
};
</script>
