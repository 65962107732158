import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_ADMINISTRACAO_PORT}${process.env.VUE_APP_API_PATH}/administracao`;

export default {
    obterTodos() {
        return axiosJwt.get(`${api}/controleacesso/usuarios`);
    },

    obterPorId(id) {
        return axiosJwt.get(`${api}/controleacesso/usuarios/${id}`);
    },

    obterPerfis(id) {
        return axiosJwt.get(`${api}/controleacesso/usuarios/${id}/perfis`);
    },

    inserir(usuario) {
        return axiosJwt.post(`${api}/controleacesso/usuarios`, usuario);
    },

    obterPorLogin(login) {
        return axiosJwt.get(`${api}/controleacesso/usuarios/login/${login}`);
    },

    atualizar(usuario) {
        return axiosJwt.put(`${api}/controleacesso/usuarios`, usuario);
    },

    associar(perfis) {
        return axiosJwt.post(`${api}/controleacesso/usuarios/associar`, perfis);
    },

    desassociar(perfis) {
        return axiosJwt.post(`${api}/controleacesso/usuarios/desassociar`, perfis);
    },

    ativar(usuarioId) {
        return axiosJwt.patch(`${api}/controleacesso/usuarios/${usuarioId}/ativar`);
    },

    desativar(usuarioId) {
        return axiosJwt.patch(`${api}/controleacesso/usuarios/${usuarioId}/desativar`);
    },

    gerarSenha(usuarioId, amigavel) {
        return axiosJwt.patch(`${api}/controleacesso/usuarios/${usuarioId}/gerarnovasenha${amigavel ? '/amigavel' : ''}`);
    },
};
